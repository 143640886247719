<script setup lang="ts">
const props = defineProps({
  items: {
    type: Array,
    required: false,
    default: () => [],
  },
  width: {
    type: String,
    required: false,
  },
})

const el = ref(null)
const dropdownEl = ref(null)
const {x, y, top, right, bottom, left, width:test123, height} = useElementBounding(el)
const isDropdownActive = ref(false)
const toggleDropdown = () => {
  isDropdownActive.value = !isDropdownActive.value
}

onClickOutside(dropdownEl, (event) => {
  isDropdownActive.value = false
})

const dropdownContentStyles = computed(() => {
  return {
    top: `${height.value + 8}px`,
    ...(props.width && {width: `${props.width}`}),
    // left: `${left.value}px`,
    // width: `${width.value}px`,
  }
})

</script>

<template>
  <div
    ref="dropdownEl"
    class="dropdown"
  >
    <div
      ref="el"
      class="trigger"
    >
      <slot
        name="trigger"
        :toggle="toggleDropdown"
      />
    </div>
    <div
      v-show="isDropdownActive"
      class="dropdown-content"
      :style="dropdownContentStyles"
    >
      <slot
        v-if="props.items.length"
        name="items"
      >
        <template
          v-for="(item, index) in items"
          :key="index"
        >
          <slot
            name="item"
            :item="item"
          >
            <AppDropdownItem
              :item="item"
              @click="toggleDropdown"
            >
              {{ item.label }}
            </AppDropdownItem>
          </slot>
        </template>
      </slot>
      <slot
        v-else
        name="empty"
      >
        No data
      </slot>
    </div>
  </div>
</template>


<style scoped>
.trigger {
  cursor: pointer;
}
.dropdown {
  position: relative;
}


.dropdown-content {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(31, 22, 59, 0.1);
  border-radius: 8px;
  /*top: 100%;*/
  /*left: 0;*/
  /*width: 100%;*/
  /*background-color: #f1f1f1;*/
  /*z-index: 1;*/
  padding: 5px;
  .item {

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #1F163B;

    padding: 10px;
    padding-right: 35px;

    &:hover {
      background: rgba(45, 45, 134, 0.1);
      border-radius: 6px;
      cursor: pointer;

    }
  }
}
</style>
