<script setup lang="ts">

</script>

<template>
  <div
    class="dropdown-item"
  >
    <slot />
  </div>
</template>


<style scoped>
.dropdown-item {

  font-family: 'Eurostile LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #1F163B;

  padding: 10px;
  padding-right: 35px;

  &:hover {
    background: rgba(45, 45, 134, 0.1);
    border-radius: 6px;
    cursor: pointer;

  }
}
</style>
