<script lang="ts" setup>
const {locale, locales} = useI18n()
const switchLocalePath = useSwitchLocalePath()
const {t} = useI18n({
  useScope: 'global'
})
const availableLocales = computed(() => {
  return (locales.value).map((i: any) => {
    return {
      ...i,
      active: i.code === locale.value
    }
  })
})

const activeLocale = computed(() => {
  return availableLocales.value.find((item: { active: boolean }) => item.active) || {};
})

const restLocales = computed(() => {
  return availableLocales.value.filter((item: { active: boolean }) => !item.active)
})

</script>

<template>
  <div class="lang-switcher-wrapper">
    <AppDropdown :items="restLocales">
      <template #trigger="{toggle}">
        <div
          class="active"
          @click="toggle"
        >
          {{ t(`lang.${activeLocale.code}`) }}
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L5 5L9 1"
              stroke="#1F163B"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </template>
      <template #item="{item}">
        <NuxtLink
          :to="switchLocalePath(item.code)"
        >
          <AppDropdownItem>
            {{ t(`lang.${item.code}`) }}
          </AppDropdownItem>
        </NuxtLink>
      </template>
    </AppDropdown>
  </div>
</template>


<style lang="scss" scoped>
.lang-switcher-wrapper {
  margin-right: 25px;
}

.lang-switcher-item ,
.active {
  position: relative;
  top: 2px;
  font-size: 14px;
  margin-right: 10px;
}
.active {
  svg {
    display: inline-flex;
  }
  &:hover {
    svg {
      background: rgba(45, 45, 134, 0.1);
    }
  }
}

</style>
